.header-div .ant-page-header {
  /*
  Can't easily get <Breadcrumb> component to work with <PageHeader> component.
  See: https://github.com/ant-design/ant-design/issues/24217
  Setting top padding to 0px so it looks better with the breadcrumbs on top.
  */
  padding-top: 0;
}

.breadcrumb-div .ant-breadcrumb {
  padding-top: 16px;
  padding-left: 24px;
}
.breadcrumb-div {
  margin-top: 16px;
  margin-right: 24px;
}
.ant-breadcrumb > span:last-child {
  font-weight: 500;
}

.header-div .ant-page-header-footer {
  margin-top: 0;
}

.ShortAndFullWidth {
  height: 50vh;
}

.Divider-root {
  border: none;
  height: 1px;
  margin: 0;
  flex-shrink: 0;
  background-color: black;
}

.wrapTextCharacters {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

/* for formatting json output outside of the editor (e.g. base 64 decode output) */
pre,code
.darkThemeJsonString { color: #CD917A; }
.darkThemeJsonNumber { color: #B6CEAA; }
.darkThemeJsonBoolean { color: #CD917A; }
.darkThemeJsonNull { color: #CD917A; }
.darkThemeJsonKey { color: #9EDCFC; }

pre,code
.lightThemeJsonString { color: #0D54A3; }
.lightThemeJsonNumber { color: #178559; }
.lightThemeJsonBoolean { color: #0D54A3; }
.lightThemeJsonNull { color: #0D54A3; }
.lightThemeJsonKey { color: #A2181C; }


.navBarWrapper {
  width: 100%;
}

.bar {
  height: 45px;
  /* layout children on a single "row" */
  display: flex;
  /* space out the left and right children */
  justify-content: space-between;
  /* center content vertically within the navbar */
  align-items: center;
}

.bar li {
  /* render li elements adjacently inside the .bar class, as opposed to rendering as a bullet list.  */
  display: inline-block;
}

.left {
  padding-left: 24px;
}

.right {
  padding-right: 24px;
}

.left, .right {
  /* moves the nav bar content a little lower from the top of the page. So it's not crammed to the edge. */
  margin-bottom: 0;
}
